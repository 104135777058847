import { FsButtonVideo, FsImage } from '@printi/ds-offset-react-core';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player/lazy';
import { useLayout } from '@mf/common/components/Media/Layout';
import Image from 'next/image';
import * as S from './Video.styles';

interface VideoProps {
  imgSrc: string;
  alt: string;
  video: string;
}

const ReactPlayer = dynamic<ReactPlayerProps>(
  () => import('react-player/lazy').then((module) => module.default),
  { ssr: false },
);

export const Video = ({ imgSrc, alt, video }: VideoProps) => {
  const [playVideo, setPlayVideo] = useState({ play: false, url: '' });

  const handlePlayVideo = (url: string) => {
    document.body.style.overflow = 'hidden';
    setPlayVideo({ play: true, url: url });
  };

  const onCloseHandler = () => {
    document.body.style.overflow = '';
    setPlayVideo({ ...playVideo, play: false });
  };

  const { isMobile } = useLayout();

  return (
    <div className="fs-row">
      <S.PreviewVideo
        showOverlay={true}
        isVisible={playVideo.play}
        onClick={onCloseHandler}
      >
        <div className="videoLoading">
          <ReactPlayer
            playing={playVideo.play}
            controls={true}
            url={playVideo.url}
            width={'100%'}
            height={'100%'}
          />
        </div>
      </S.PreviewVideo>
      <div className="fs-col-md-12">
        <S.Container onClick={() => handlePlayVideo(video)}>
          <FsImage
            contentSlot={<Image src={imgSrc} alt={alt} fill />}
            aspectRatio={isMobile ? '1:1' : '16:9'}
          />
          <S.ButtonVideo>
            <FsButtonVideo />
          </S.ButtonVideo>
        </S.Container>
      </div>
    </div>
  );
};
