import { useLayout } from '@mf/common/components/Media/Layout';
import {
  FlagType,
  FsDivider,
  FsFlag,
  FsHeading,
  FsParagraph,
  HeadingSize,
  IconPosition,
} from '@printi/ds-offset-react-core';
import * as S from './TextBlog.styles';

export interface TextContentProps {
  subHeading: string;
  paragraph: string;
}

export interface TextBlogProps {
  heading?: string | React.ReactNode;
  items?: string[] | React.ReactNode[];
  subheadingItems?: TextContentProps[];
  headingSize?: 'large' | undefined;
  onClickButton?: () => void;
  flag?: boolean;
}

export const TextBlog = ({
  heading,
  items,
  headingSize,
  flag = false,
  subheadingItems,
}: TextBlogProps) => {
  const { isMobile } = useLayout();

  const renderHeadingSize = () => {
    switch (headingSize) {
      case 'large':
        return isMobile ? HeadingSize.XS : HeadingSize.MD;
      default:
        return HeadingSize.XS;
    }
  };

  return (
    <S.Container>
      <FsDivider />
      <S.Info>
        <FsHeading size={renderHeadingSize()}>{heading}</FsHeading>
        {subheadingItems ? (
          <S.ContentWrapper>
            {subheadingItems &&
              subheadingItems.map((topic, index) => (
                <S.TextWrapper key={index}>
                  <FsHeading size={HeadingSize.SM}>
                    {topic.subHeading}
                  </FsHeading>
                  <FsParagraph>{topic.paragraph}</FsParagraph>
                </S.TextWrapper>
              ))}
          </S.ContentWrapper>
        ) : (
          <S.ContentWrapper>
            {items &&
              items.map((topic, index) =>
                typeof topic === 'string' ? (
                  <FsParagraph key={index}>{topic}</FsParagraph>
                ) : (
                  <S.DivParagraphStyle key={index}>{topic}</S.DivParagraphStyle>
                ),
              )}
          </S.ContentWrapper>
        )}

        {flag && (
          <FsFlag type={FlagType.Warning} iconPosition={IconPosition.Left}>
            A aceitação destes ”Termos de Uso” é condição indispensável para a
            utilização dos serviços e a aquisição de produtos da Printi e
            poderão ser atualizados e modificados ao longo do tempo.
          </FsFlag>
        )}
      </S.Info>
    </S.Container>
  );
};
