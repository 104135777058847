import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  cursor: pointer;
  overflow: hidden;

  pointer-events: all;
  img {
    transform: scale(1);
    transition-property: transform;
    transition-timing-function: var(--curve-productive-exit);
    transition-duration: var(--duration-moderate-02);
  }

  &:hover img {
    transform: scale(1.2);
    transition-timing-function: var(--curve-productive-entrance);
    transition-duration: var(--duration-moderate-02);
  }
`;

export const ButtonVideo = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: fit-content;
  height: fit-content;
`;

export const PreviewVideo = styled.div.withConfig({
  shouldForwardProp: (prop) => !['showOverlay', 'isVisible'].includes(prop),
})<{ showOverlay: boolean; isVisible: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  inset: 0;
  box-sizing: border-box;
  transition: opacity 400ms ease-in-out;
  align-items: center;
  justify-content: center;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};

  ${({ showOverlay }) =>
    showOverlay && 'background-color: var(--_default-overlay);'}

  .videoLoading {
    width: 90vw;
    height: 40vh;
    max-width: 800px;
    max-height: max-content;
    overflow: hidden;
    position: relative;
    background-color: var(--elements-bg-color-02);
    box-shadow: var(--shadow-level-3);

    ${media.greaterThan('mobile')} {
      min-height: 456px;
    }
  }
`;
