import { media } from '@mf/common/utils/styles/index';
import { FsButtonGroup, FsDescription } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-5x) 0;

  ${media.greaterThan('mobile')} {
    padding: var(--spacing-15x) 0;
  }

  @media (max-width: 1025px) {
    padding: var(--spacing-10x) 0;
  }
`;

export const AvatarContainer = styled.div`
  padding-bottom: var(--spacing-4x);
`;

export const Description = styled(FsDescription)`
  padding-bottom: var(--spacing-4x);
  text-align: center;
`;

export const HeadingWrapper = styled.div`
  text-align: center;
`;

export const ButtonGroup = styled(FsButtonGroup)`
  padding-top: var(--spacing-10x);
`;

export const ButtonWrapper = styled.div`
  margin-top: var(--spacing-10x);
`;
