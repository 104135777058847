import media from '@mf/common/utils/styles/media';
import { FsHeading, FsParagraph } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: var(--spacing-10x) 0 var(--spacing-15x) 0;
  position: relative;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-3x) 0;
  }
`;

export const Info = styled.div`
  display: grid;
  gap: var(--spacing-5x);
`;

export const HeadingTagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1x);

  div {
    max-height: 24px;
  }
`;

export const Heading = styled(FsHeading)`
  max-width: 862px;
`;

export const Paragraph = styled(FsParagraph)`
  max-width: 440px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-1x);
  margin-top: var(--spacing-5x);
  padding-bottom: var(--spacing-half);

  ${media.greaterThan('mobile')} {
    margin-top: 0;
    position: absolute;
    right: 0;
    bottom: var(--spacing-15x);
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: var(--spacing-10x);
  margin-right: 100%;
`;
