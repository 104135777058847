import { useLayout } from '@mf/common/components/Media/Layout';
import {
  FsHeading,
  FsIcon,
  FsParagraph,
  HeadingSize,
  HeadingTag,
  IconSetType,
  IconSize,
} from '@printi/ds-offset-react-core';
import React from 'react';
import * as S from './CardSectionTopics.styles';

interface Card {
  heading: string;
  description: string;
  inverse: boolean;
}

interface IconDefault extends Card {
  icon: IconSetType | symbol;
  customIcon?: false;
  alt?: string;
}

interface IconCustom extends Card {
  icon: string;
  alt: string;
  customIcon: true;
}

export type CardSectionTopicsProps = IconDefault | IconCustom;

export const CardSectionTopics = ({
  icon,
  customIcon = false,
  alt,
  heading,
  description,
  inverse,
}: CardSectionTopicsProps) => {
  const { isMobile } = useLayout();

  const renderIcon = (): React.ReactNode => {
    if (customIcon) {
      return (
        <S.CustomImage
          src={icon as string}
          width={48}
          height={48}
          alt={alt as string}
        />
      );
    }

    return (
      <S.IconWrapper>
        <FsIcon
          icon={icon as IconSetType}
          size={isMobile ? IconSize.MD : IconSize.LG}
          inverse={inverse}
        />
      </S.IconWrapper>
    );
  };

  return (
    <S.Container $inverse={inverse}>
      {renderIcon()}
      <S.HeadingWrapper>
        <FsHeading
          size={isMobile ? HeadingSize.SM : HeadingSize.MD}
          inverse={inverse}
          tag={HeadingTag.H2}
        >
          {heading}
        </FsHeading>
      </S.HeadingWrapper>
      <FsParagraph inverse={inverse}>{description}</FsParagraph>
    </S.Container>
  );
};
