import * as S from './VideoFull.styles';

export interface VideoFullProps {
  video?: string;
}
export const VideoFull = ({ video }: VideoFullProps) => {
  return (
    <S.Container>
      <S.Video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        disablePictureInPicture
      >
        <source src={video} type="video/webm" />
      </S.Video>
    </S.Container>
  );
};
