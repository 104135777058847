import { FsAccordion, FsAccordionGroup } from '@printi/ds-offset-react-core';
import { ReactNode } from 'react';

export type TFaqItemProps = {
  id?: number;
  question: string;
  answer: ReactNode;
  answerLink?: string;
};

export const FaqItems = ({ items }: { items?: TFaqItemProps[] }) => {
  return (
    <FsAccordionGroup>
      {items?.map((faq, index) => (
        <FsAccordion
          key={faq.question}
          index={index}
          id="accordion-id"
          label={faq.question}
          headingLevel={3}
        >
          {faq.answer}
        </FsAccordion>
      ))}
    </FsAccordionGroup>
  );
};
