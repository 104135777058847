import styled from 'styled-components';

export const Container = styled.div`
  max-width: 660px;
  margin: 0 auto;
`;

export const Info = styled.div`
  margin-top: var(--spacing-7x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5x);

  a {
    text-decoration: underline;
  }

  ul {
    margin: 0;
    padding-left: 24px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
`;

export const TextWrapper = styled.div`
  margin-bottom: var(--spacing-2x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
`;

export const DivParagraphStyle = styled.div`
  color: var(--text-paragraph-color);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xs);
  letter-spacing: var(--letter-spacing-default);
  line-height: var(--line-height-lg);
  margin: 0;
`;
