import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-15x) var(--spacing-5x);
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: var(--spacing-4x);
  }

  ${media.lessThan('mobile')} {
    padding: var(--spacing-2x) var(--spacing-2x) var(--spacing-7x)
      var(--spacing-2x);
    align-items: flex-start;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const SectionsContainer = styled.div`
  display: grid;
  gap: var(--spacing-10x);
  width: 100%;

  ${media.lessThan('mobile')} {
    gap: var(--spacing-6x);
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
  align-self: stretch;
`;

export const FaqSection = styled.div`
  display: flex;
  padding: var(--spacing-15x);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-5x);

  border-radius: var(--border-radius-md);
  border: 1.6px solid var(--elements-border, #f5f5f5);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-8x) var(--spacing-2x);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;

  > * {
    position: relative;
  }
`;
