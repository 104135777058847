import {
  FsHeading,
  FsTopicIcon,
  FsTopicIconProps,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import React from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './TopicIconsList.styles';

type ColumnType = {
  topicIcons: FsTopicIconProps[];
};

interface TopicIconsGroupProps {
  heading?: string | React.ReactNode;
  columns: ColumnType[];
}

export const TopicIconsList = ({ heading, columns }: TopicIconsGroupProps) => {
  const { isMobile } = useLayout();

  return (
    <S.Container>
      <FsHeading
        size={isMobile ? HeadingSize.MD : HeadingSize.LG}
        tag={HeadingTag.H2}
      >
        {heading}
      </FsHeading>
      <S.ColumnsContainer>
        {columns.map((column, index) => (
          <S.Columns key={index}>
            {column.topicIcons.map((topicIcon, index) => (
              <S.TopicIconWrapper key={index} forwardedAs={undefined}>
                <FsTopicIcon {...topicIcon} as={undefined} />
              </S.TopicIconWrapper>
            ))}
          </S.Columns>
        ))}
      </S.ColumnsContainer>
    </S.Container>
  );
};
