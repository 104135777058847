import {
  ButtonSecondarySize,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import Link from 'next/link';
import * as S from './SectionContentVideo.styles';
export interface SectionContentVideoProps {
  contentPosition?: 'left' | 'right';
  sectionType?: 'default' | 'h2';
  heading?: string | React.ReactNode;
  description?: string;
  buttonLabel?: string;
  href?: string;
  video: string;
  onButtonClick?: () => void;
}
export const SectionContentVideo = ({
  contentPosition = 'left',
  sectionType = 'default',
  heading,
  description,
  buttonLabel,
  video,
  onButtonClick,
  href,
}: SectionContentVideoProps) => {
  const { isMobile } = useLayout();
  return (
    <div className="fs-row">
      <S.Container
        className={
          contentPosition === 'left'
            ? 'fs-col-md-11 fs-col-offset-md-2'
            : 'fs-col-md-12'
        }
      >
        <S.Content
          $contentPosition={contentPosition}
          $sectionType={sectionType}
        >
          <S.Info>
            <S.HeadingWrapper>
              <FsHeading
                size={
                  sectionType === 'default' && !isMobile
                    ? HeadingSize['2XL']
                    : HeadingSize.MD
                }
                tag={HeadingTag.H2}
              >
                {heading}
              </FsHeading>
            </S.HeadingWrapper>
            <S.Paragraph>{description}</S.Paragraph>
            <Link href={href || '#'}>
              <S.ButtonSecondary
                onClick={onButtonClick}
                size={ButtonSecondarySize.LG}
              >
                {buttonLabel}
              </S.ButtonSecondary>
            </Link>
          </S.Info>
          <S.VideoContainer>
            {video && (
              <video
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
                disablePictureInPicture
              >
                <source src={video} type="video/webm" />
              </video>
            )}
          </S.VideoContainer>
        </S.Content>
      </S.Container>
    </div>
  );
};
