import {
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { CardSectionTopics, CardSectionTopicsProps } from './CardSectionTopics';
import * as S from './SectionTopics.styles';

interface SectionTopicsProps {
  heading: string | React.ReactNode;
  topics: CardSectionTopicsProps[];
}

export const SectionTopics = ({ heading, topics }: SectionTopicsProps) => {
  const { isMobile } = useLayout();

  return (
    <S.Container>
      <S.HeadingWrapper>
        <FsHeading
          size={isMobile ? HeadingSize.MD : HeadingSize.XL}
          tag={HeadingTag.H2}
        >
          {heading}
        </FsHeading>
      </S.HeadingWrapper>
      <S.TopicsContainer>
        {topics &&
          topics.map((topic, index) => (
            <S.TopicItem $position={index % 2 ? 'down' : 'up'} key={index}>
              <CardSectionTopics {...topic} />
            </S.TopicItem>
          ))}
      </S.TopicsContainer>
    </S.Container>
  );
};
