import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const Container = styled.div`
  padding: var(--spacing-10x) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7x);
`;

export const HeadingWrapper = styled.div`
  padding: var(--spacing-10x) var(--spacing-15x);
  ${media.lessThan('mobile')} {
    padding: var(--spacing-4x) 0;
  }
`;

export const TopicsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  ${media.lessThan('mobile')} {
    flex-direction: column;
  }
`;

export const TopicItem = styled.div<{ $position: 'up' | 'down' }>`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ $position }) =>
    $position === 'up' &&
    `
        margin-bottom: 442px;
    `}

  ${({ $position }) =>
    $position === 'down' &&
    `
        margin-top: 442px;
    `}

    ${media.lessThan('mobile')} {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
