import { css } from 'styled-components';
import { FsButtonSecondary, FsParagraph } from '@printi/ds-offset-react-core';
import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Container = styled.div`
  padding: var(--spacing-15x) 0;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-6x) 0;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  ${media.lessThan('mobile')} {
    justify-content: flex-start;
  }
  video {
    width: 100%;
    max-width: 551px;
    border-radius: 50%;
    ${media.lessThan('mobile')} {
      max-width: 96px;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 664px;
  min-width: 50%;
  justify-content: center;
  align-items: flex-start;
`;

export const HeadingWrapper = styled.div`
  margin-bottom: var(--spacing-3x);
`;

export const Paragraph = styled(FsParagraph)`
  max-width: 420px;
  margin-bottom: var(--spacing-7x);
`;

export const ButtonSecondary = styled(FsButtonSecondary)`
  width: fit-content;
`;

export const Content = styled.div<{
  $contentPosition: 'left' | 'right';
  $sectionType: 'default' | 'h2';
}>`
  display: flex;
  gap: var(--spacing-10x);
  ${HeadingWrapper} {
    margin-bottom: ${({ $sectionType }) =>
      $sectionType === 'default' ? 'var(--spacing-3x)' : 'auto'};
  }
  ${({ $contentPosition }) =>
    $contentPosition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
  ${media.lessThan('mobile')} {
    flex-direction: column-reverse;
    gap: var(--spacing-3x);
    ${HeadingWrapper} {
      margin-bottom: var(--spacing-3x);
    }
    ${Info} {
      padding-top: 0;
    }
  }
`;
