import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const Container = styled.div`
  padding: var(--spacing-5x) 0;

  a {
    text-decoration: underline;
  }

  ${media.greaterThan('mobile')} {
    padding: var(--spacing-10x) 0;
  }
`;

export const HeadingWrapper = styled.div`
  margin-bottom: var(--spacing-7x);
`;

export const TopicIconsContainer = styled.div`
  display: grid;

  > div:not(:last-child) {
    border-bottom: 0;
    border-right: var(--default-border-width) solid var(--elements-border-color);
  }

  ${media.greaterThan('touch')} {
    display: flex;

    > div {
      width: 100%;
    }

    > div:not(:last-child) {
      border-right: 0;
      border-bottom: var(--default-border-width) solid
        var(--elements-border-color);
    }
  }
`;

export const TopicIconsDoubleContainer = styled.div`
  display: grid;
  > div:not(:last-child) {
    border-bottom: 0;
  }

  ${media.greaterThan('mobile')} {
    grid-template-columns: repeat(2, 1fr);

    > div:nth-child(odd) {
      border-bottom: 0;
      border-right: 0;
    }

    > div:nth-child(7) {
      border-bottom: var(--default-border-width) solid
        var(--elements-border-color);
      border-right: 0;
    }

    > div:nth-child(even):not(:last-child) {
      border-bottom: 0;
    }
  }

  ${media.greaterThan('touch')} {
    grid-template-columns: repeat(4, 1fr);

    > div:nth-child(2) {
      border-right: 0;
    }

    > div:nth-child(5) {
      border-right: 0;
      border-bottom: var(--default-border-width) solid
        var(--elements-border-color);
    }

    > div:nth-child(6) {
      border-right: 0;
      border-bottom: var(--default-border-width) solid
        var(--elements-border-color) !important;
    }
  }
`;

// export const TopicNoIcon = styled(FsTopicIcon)`
//   > div:first-child {
//     display: none;
//   }
// `;
export const TopicNoIcon = styled.div`
  > div {
    > div:first-child {
      display: none;
    }
  }
`;
