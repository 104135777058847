import {
  FsHeading,
  FsTopicIcon,
  FsTopicIconProps,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './TopicIconsGroup.styles';

interface TopicIconsGroupProps {
  heading?: string | React.ReactNode;
  topicIcons?: FsTopicIconProps[];
  noIcon?: boolean;
  double?: boolean;
}

export const TopicIconsGroup = ({
  heading,
  topicIcons,
  noIcon = false,
  double = false,
}: TopicIconsGroupProps) => {
  const { isMobile } = useLayout();

  return (
    <S.Container>
      {heading && (
        <S.HeadingWrapper>
          <FsHeading
            size={isMobile ? HeadingSize.MD : HeadingSize.LG}
            tag={HeadingTag.H2}
          >
            {heading}
          </FsHeading>
        </S.HeadingWrapper>
      )}
      {double ? (
        <S.TopicIconsDoubleContainer>
          {topicIcons &&
            topicIcons.map((topic, index) =>
              noIcon ? (
                <S.TopicNoIcon key={index}>
                  <FsTopicIcon {...topic} key={index} />
                </S.TopicNoIcon>
              ) : (
                <FsTopicIcon {...topic} key={index} />
              ),
            )}
        </S.TopicIconsDoubleContainer>
      ) : (
        <S.TopicIconsContainer>
          {topicIcons &&
            topicIcons.map((topic, index) =>
              noIcon ? (
                <S.TopicNoIcon key={index}>
                  <FsTopicIcon {...topic} key={index} />
                </S.TopicNoIcon>
              ) : (
                <FsTopicIcon {...topic} key={index} />
              ),
            )}
        </S.TopicIconsContainer>
      )}
    </S.Container>
  );
};
