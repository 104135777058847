import {
  ButtonSecondarySize,
  FsHeading,
  FsImage,
  FsImageProps,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import Link from 'next/link';
import { ReactNode } from 'react';
import * as S from './SectionContent.styles';

export interface SectionContentProps {
  contentPosition?: 'left' | 'right';
  sectionType?: 'default' | 'h2';
  heading?: string | ReactNode;
  description?: string;
  image?: FsImageProps;
  buttonLabel?: string;
  href?: string;
  headingSize?: 's' | 'm';
}

export const SectionContent = ({
  contentPosition = 'left',
  sectionType = 'default',
  heading,
  description,
  image,
  buttonLabel,
  headingSize,
  href,
}: SectionContentProps) => {
  const { isMobile } = useLayout();

  const renderHeadingSize = () => {
    switch (headingSize) {
      case 's':
        return isMobile ? HeadingSize.MD : HeadingSize.LG;
      case 'm':
        return isMobile ? HeadingSize.MD : HeadingSize.XL;
      default:
        return isMobile ? HeadingSize.MD : HeadingSize['2XL'];
    }
  };

  return (
    <div className="fs-row">
      <S.Container
        className={
          contentPosition === 'left'
            ? 'fs-col-md-11 fs-col-offset-md-2'
            : 'fs-col-md-12'
        }
      >
        <S.Content
          $contentPosition={contentPosition}
          $sectionType={sectionType}
        >
          <S.Info>
            {headingSize === 's'}
            <S.HeadingWrapper>
              <FsHeading
                size={
                  sectionType === 'default' ? renderHeadingSize() : undefined
                }
                tag={HeadingTag.H2}
              >
                {heading}
              </FsHeading>
            </S.HeadingWrapper>
            {buttonLabel ? (
              <S.ParagraphMarginBottom>{description}</S.ParagraphMarginBottom>
            ) : (
              <S.Paragraph>{description}</S.Paragraph>
            )}

            {buttonLabel && (
              <Link href={href || '#'}>
                <S.ButtonSecondary size={ButtonSecondarySize.LG}>
                  {buttonLabel}
                </S.ButtonSecondary>
              </Link>
            )}
          </S.Info>
          <S.ImageContainer>
            {image && (
              <FsImage
                {...image}
                aspectRatio={contentPosition === 'left' ? '1:1' : 'none'}
              />
            )}
          </S.ImageContainer>
        </S.Content>
      </S.Container>
    </div>
  );
};
