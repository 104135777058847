import { media } from '@mf/common/utils/styles/index';
import Image from 'next/image';
import styled, { keyframes } from 'styled-components';

const lettering = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5x);
  overflow: hidden;
`;

export const Clients = styled.div`
  height: 45px;
  display: flex;
  gap: var(--spacing-8x);
  ${media.lessThan('mobile')} {
    gap: var(--spacing-4x);
  }
  animation: 35s ${lettering} infinite linear;
`;

export const Carousel = styled.div`
  width: max-content;
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
  display: flex;
  gap: var(--spacing-8x);
  ${media.lessThan('mobile')} {
    gap: var(--spacing-4x);
  }

  ${media.greaterThan('mobile')} {
    &:hover ${Clients} {
      animation-play-state: paused;
    }
  }
`;

export const ClientImage = styled(Image)`
  width: 100%;
  height: 100%;

  ${media.lessThan('mobile')} {
    width: 80%;
    height: 80%;
  }
`;
