import { media } from '@mf/common/utils/styles/index';
import { FsParagraph } from '@printi/ds-offset-react-core';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--spacing-4x) var(--spacing-3x);
  background-color: var(--elements-bg-color-02);
  min-width: 290px;
  max-width: 290px;
  height: 322px;
  box-sizing: border-box;
  transition: all 150ms ease-in-out;

  ${media.greaterThan('mobile')} {
    min-width: 322px;
    max-width: 322px;
    padding: var(--spacing-5x);

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
`;

const Image = styled.img``;

export interface CardPressProps {
  src?: string;
  description?: string;
  alt?: string;
  href: string;
}

export const CardPress = ({ src, description, alt, href }: CardPressProps) => {
  return (
    <Link target="_blank" href={href}>
      <CardWrapper>
        <Image src={src} alt={alt} />
        <FsParagraph>{description}</FsParagraph>
      </CardWrapper>
    </Link>
  );
};
