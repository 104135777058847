import { useLayout } from '@mf/common/components/Media/Layout';
import {
  ButtonGroupItemVariant,
  ButtonGroupOrientation,
  ButtonSize,
  FsAvatar,
  FsAvatarProps,
  FsButton,
  FsButtonGroupItem,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import * as S from './HighlightContent.styles';

type ButtonType = {
  label: string;
  ariaLabel?: string;
  onClick?: () => void;
};

type ButtonInGroupType = {
  label: string;
  ariaLabel?: string;
  onClick?: () => void;
};

interface HighlightContentProps {
  avatar?: FsAvatarProps;
  description?: string;
  heading?: string | React.ReactNode;
  buttonGroup?: {
    primary: ButtonInGroupType;
    secondary: ButtonInGroupType;
  };
  button?: ButtonType;
}

export const HighlightContent = ({
  avatar,
  description,
  heading,
  buttonGroup,
  button,
}: HighlightContentProps) => {
  const { isMobile } = useLayout();

  return (
    <div className="fs-row">
      <S.Container className="fs-col-md-10 fs-col-offset-md-2">
        {avatar && (
          <S.AvatarContainer>
            <FsAvatar {...avatar} />
          </S.AvatarContainer>
        )}
        <S.Description>{description}</S.Description>
        <S.HeadingWrapper>
          <FsHeading
            size={isMobile ? HeadingSize.MD : HeadingSize.XL}
            tag={HeadingTag.H2}
          >
            {heading}
          </FsHeading>
        </S.HeadingWrapper>
        {buttonGroup && (
          <S.ButtonGroup
            size="lg"
            orientation={
              isMobile
                ? ButtonGroupOrientation.Vertical
                : ButtonGroupOrientation.Horizontal
            }
          >
            <FsButtonGroupItem
              slot="primary"
              aria-label={buttonGroup?.primary.ariaLabel}
              onClick={buttonGroup?.primary.onClick}
            >
              {buttonGroup?.primary.label}
            </FsButtonGroupItem>
            <FsButtonGroupItem
              slot="secondary"
              variant={ButtonGroupItemVariant.Secondary}
              aria-label={buttonGroup?.secondary.ariaLabel}
              onClick={buttonGroup?.secondary.onClick}
            >
              {buttonGroup?.secondary.label}
            </FsButtonGroupItem>
          </S.ButtonGroup>
        )}
        {button && (
          <S.ButtonWrapper>
            <FsButton
              size={ButtonSize.LG}
              onClick={button?.onClick}
              aria-label={button?.ariaLabel}
            >
              {button?.label}
            </FsButton>
          </S.ButtonWrapper>
        )}
      </S.Container>
    </div>
  );
};
