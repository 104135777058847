import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: var(--spacing-9x);
  max-width: 1440px;
  margin-inline: auto;

  ${media.greaterThan('mobile')} {
    gap: var(--spacing-4x);
    padding: 0 var(--spacing-5x);
    margin-bottom: var(--spacing-25x);
    margin-top: -40px;
  }
`;

export const HeadingControlsWrapper = styled.div`
  padding: 0 var(--spacing-2x);
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  ${media.greaterThan('mobile')} {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: none;
  gap: var(--spacing-1x);

  ${media.greaterThan('mobile')} {
    display: flex;
  }
`;

export const CardsCarouselContainer = styled.div`
  overflow-x: scroll;
  padding-bottom: var(--spacing-half);
  width: 100%;
  max-width: 100%;
  display: flex;
  box-sizing: border-box;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.lessThan('mobile')} {
    padding: 0 16px;
    width: 100vw;
  }
`;

export const CardsCarouselWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2x);
  transition: transform 250ms ease-in-out;
  width: max-content;
  box-sizing: border-box;

  ${media.greaterThan('mobile')} {
    gap: var(--spacing-3x);
  }
`;
