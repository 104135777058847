import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';
import { FsParagraph } from '@printi/ds-offset-react-core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('mobile')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.greaterThan('mobile')} {
    width: 50%;
    max-width: 608px;
  }
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);

  ${media.greaterThan('mobile')} {
    gap: var(--spacing-2x);
    max-width: 435px;
  }
`;

export const SecondInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1_5x);
  padding-top: var(--spacing-5x);

  ${media.greaterThan('mobile')} {
    gap: var(--spacing-2x);
    padding-top: 0;
    max-width: 400px;
  }
`;

export const FormWrapper = styled.div`
  margin-top: var(--spacing-8x);
  margin-bottom: var(--spacing-2x);
  padding: var(--spacing-4x) var(--spacing-3x);
  border: var(--default-border-width) solid var(--elements-border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--elements-bg-color-01);

  ${media.greaterThan('mobile')} {
    padding: var(--spacing-4x) var(--spacing-5x) var(--spacing-6x)
      var(--spacing-5x);
    margin-top: 0;
    margin-bottom: 0;
    width: 50%;
    max-width: 560px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2x);

  textarea {
    z-index: 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2x);

  ${media.greaterThan('mobile')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-3x);
  }
`;

export const HeadingStepperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--spacing-6x);
  margin-bottom: var(--spacing-4x);
  margin-top: var(--spacing-2x);

  ${media.greaterThan('mobile')} {
    margin-bottom: var(--spacing-5x);
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: var(--spacing-1x);

  ${media.greaterThan('mobile')} {
    margin-top: var(--spacing-2x);
  }
`;

export const StepperWrapper = styled.div`
  > div {
    gap: 0;
    > div {
      gap: var(--spacing-4x);
      height: var(--spacing-3x);
      > div {
        > span {
          height: var(--spacing-3x);
          width: var(--spacing-3x);
        }

        > span:last-child {
          display: none;
        }
      }
    }
  }
`;

export const CheckboxWrapper = styled.div``;

export const TermsPrivacyWarning = styled(FsParagraph)`
  margin-top: var(--spacing-2x);

  a {
    font-weight: 500;
    text-decoration: underline;
  }
`;
