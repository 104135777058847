import media from '@mf/common/utils/styles/media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-10x) 0;
  gap: var(--spacing-9x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-5x) 0;
    gap: var(--spacing-5x);
  }
`;

export const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-4x);

  ${media.lessThan('mobile')} {
    grid-template-columns: 1fr;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2x);
`;

export const TopicIconWrapper = styled.div`
  > div {
    border: var(--border-width-none);
    padding: var(--spacing-none);
    div + div > * {
      padding-bottom: 0;
    }
  }
`;
