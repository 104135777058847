/* eslint-disable import/order */

'use client';

import React from 'react';
import {
  FsImage,
  FsTopSection,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { TopPage } from '@components/main/SecondariesPages/TopPage';
import { HighlightContent } from '@components/main/SecondariesPages/HighlightContent';
import { TopicIconsGroup } from '@components/main/SecondariesPages/TopicIconsGroup';
import { SectionContent } from '@components/main/SecondariesPages/SectionContent';
import { SectionContentVideo } from '@components/main/SecondariesPages/SectionContentVideo';
import { Video } from '@/components/main/SecondariesPages/Video';
import {
  ArraySections,
  ContentType,
  FaqProps,
} from '@/app/(pages)/institucional/content';
import { SectionTopics } from '@/components/main/SecondariesPages/SectionTopics/SectionTopics';
import { CarouselClients } from '@/components/main/SecondariesPages/CarouselClients';
import { TopicIconsList } from '@/components/main/SecondariesPages/TopicIconsList';
import { CarouselPress } from '@/components/main/SecondariesPages/CarouselPress';
import { TextBlog } from '@/components/main/SecondariesPages/TextBlog';
import { VideoFull } from '@/components/main/SecondariesPages/VideoFull';
import { ContactSection } from '@/components/main/SecondariesPages/ContactSection';
// eslint-disable-next-line import/order
import * as S from './Secondaries.styles';
import { FaqItems } from '@/components/main/FaqItems';
import Image from 'next/image';

interface SecondariesScreenProps {
  content: ContentType[];
}

const renderFaq = (props: FaqProps) => {
  return (
    <S.FaqWrapper key={props.id}>
      <FsTopSection
        heading={props.heading}
        showDescription={false}
        headingProps={{
          tag: HeadingTag.H2,
        }}
      />
      <FaqItems items={props.faqItems} />
    </S.FaqWrapper>
  );
};

export function SecondariesScreen({ content }: SecondariesScreenProps) {
  return content.map((props) => {
    if (props.type === 'grid') {
      return (
        <>
          <S.Grid>
            <S.SectionsContainer>
              {renderConditionalContent(props.sections)}
            </S.SectionsContainer>
          </S.Grid>
        </>
      );
    }
    return <>{renderConditionalContent(props.sections)}</>;
  });
}

function renderConditionalContent(content: ArraySections) {
  if (!content.length) {
    return <></>;
  }

  return content.map((props) => {
    if (props.__component === 'sections.faq_section') {
      return (
        <S.FaqSection key={props.id}>
          {props.faq.map((faq) => (
            <React.Fragment key={faq.id}>{renderFaq(faq)}</React.Fragment>
          ))}
        </S.FaqSection>
      );
    }

    if (props.__component === 'sections.faq') {
      return renderFaq(props);
    }

    if (props.__component === 'sections.toppage') {
      return (
        <TopPage
          heading={props.heading}
          description={props.description}
          key={props.id}
          contactheading={props.contactheading}
          contactdescription={props.contactdescription}
          tagLabel={props.tagLabel}
        />
      );
    }

    if (props.__component === 'sections.image') {
      const { isMobile } = useLayout();
      const renderImage = () => {
        if (props.imageMobile && isMobile) {
          return props.imageMobile.src;
        }
        return props.image.src;
      };

      return (
        <S.ImageWrapper key={props.id}>
          <FsImage
            contentSlot={
              <Image src={renderImage()} alt={props.image.alt} fill />
            }
            aspectRatio={isMobile ? '1:1' : '21:9'}
          />
        </S.ImageWrapper>
      );
    }

    if (props.__component === 'sections.highlightcontent') {
      return (
        <HighlightContent
          avatar={props.avatar}
          description={props.description}
          heading={props.heading}
          buttonGroup={props.buttonGroup}
          button={props.button}
          key={props.id}
        />
      );
    }

    if (props.__component === 'sections.topiciconsgroup') {
      return (
        <TopicIconsGroup
          heading={props.heading}
          topicIcons={props.topicIcons}
          key={props.id}
          noIcon={props.noIcon}
          double={props.double}
        />
      );
    }

    if (props.__component === 'sections.textblog') {
      return (
        <TextBlog
          heading={props.heading}
          items={props.items}
          key={props.id}
          headingSize={props.headingSize}
          onClickButton={props.onClickButton}
          flag={props.flag}
          subheadingItems={props.subheadingItems}
        />
      );
    }

    if (props.__component === 'sections.sectioncontent') {
      return (
        <SectionContent
          contentPosition={props.contentPosition}
          sectionType={props.sectionType}
          heading={props.heading}
          headingSize={props.headingSize}
          description={props.description}
          image={props.image}
          buttonLabel={props.buttonLabel}
          key={props.id}
          href={props.href}
        />
      );
    }

    if (props.__component === 'sections.contactsection') {
      return (
        <ContactSection
          key={props.id}
          mainHeading={props.mainHeading}
          mainParagraph={props.mainParagraph}
          secondHeading={props.secondHeading}
          secondParagraph={props.secondParagraph}
        />
      );
    }

    if (props.__component === 'sections.sectioncontentvideo') {
      const video = props.video || '';
      return (
        <SectionContentVideo
          contentPosition={props.contentPosition}
          sectionType={props.sectionType}
          heading={props.heading}
          description={props.description}
          video={video}
          buttonLabel={props.buttonLabel}
          key={props.id}
          onButtonClick={props.onButtonClick}
          href={props.href}
        />
      );
    }

    if (props.__component === 'sections.videofull') {
      const video = props.video || '';
      return <VideoFull video={video} key={props.id} />;
    }

    if (props.__component === 'sections.video') {
      return (
        <Video
          imgSrc={props.image.src}
          alt={props.image.alt}
          video={props.video}
          key={props.id}
        />
      );
    }

    if (props.__component === 'sections.sectiontopics') {
      return (
        <SectionTopics
          heading={props.heading}
          topics={props.topics}
          key={props.id}
        />
      );
    }

    if (props.__component === 'sections.carouselclients') {
      return (
        <CarouselClients
          heading={props.heading}
          clients={props.clients}
          key={props.id}
        />
      );
    }

    if (props.__component === 'sections.carouselpress') {
      return (
        <CarouselPress
          heading={props.heading}
          cards={props.clients}
          key={props.id}
        />
      );
    }

    if (props.__component === 'sections.topiciconslist') {
      return (
        <TopicIconsList
          heading={props.heading}
          columns={props.columns}
          key={props.id}
        />
      );
    }
  });
}
