import {
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './CarouselClients.styles';

interface CarouselClientsProps {
  heading: string;
  clients: Array<{
    src: string;
    alt: string;
  }>;
}

export const CarouselClients = ({ heading, clients }: CarouselClientsProps) => {
  const { isMobile } = useLayout();

  return (
    <S.Container>
      <FsHeading
        size={isMobile ? HeadingSize.SM : HeadingSize.MD}
        tag={HeadingTag.H3}
      >
        {heading}
      </FsHeading>
      <S.Carousel>
        <S.Clients>
          {clients.map((client, index) => (
            <S.ClientImage
              key={index}
              src={client.src}
              alt={client.alt}
              height={30}
              width={120}
            />
          ))}
        </S.Clients>
        <S.Clients>
          {clients.map((client, index) => (
            <S.ClientImage
              key={index}
              src={client.src}
              alt={client.alt}
              height={30}
              width={120}
            />
          ))}
        </S.Clients>
      </S.Carousel>
    </S.Container>
  );
};
