import { useLayout } from '@mf/common/components/Media/Layout';
import {
  FsButtonSecondary,
  FsDescription,
  FsHeading,
  FsTag,
  HeadingSize,
  HeadingTag,
  TagType,
} from '@printi/ds-offset-react-core';
import * as S from './TopPage.styles';

interface TopPageProps {
  heading?: string | React.ReactNode;
  description?: string | React.ReactNode;
  contactheading?: string;
  contactdescription?: string;
  buttonLabel?: string;
  tagLabel?: string;
  onClickButton?: () => void;
}

export const TopPage = ({
  heading,
  description,
  contactheading,
  contactdescription,
  buttonLabel,
  onClickButton,
  tagLabel,
}: TopPageProps) => {
  const { isMobile } = useLayout();

  return (
    <div className="fs-row">
      <S.Container className="fs-col-md-10 fs-col-offset-md-2">
        <S.Info>
          <S.HeadingTagWrapper>
            <S.Heading size={isMobile ? HeadingSize.LG : HeadingSize['2XL']}>
              {heading}
            </S.Heading>
            {tagLabel && <FsTag type={TagType.Black} label={tagLabel} />}
          </S.HeadingTagWrapper>
          <S.Paragraph>{description}</S.Paragraph>
          {buttonLabel && (
            <S.Action>
              <FsButtonSecondary onClick={onClickButton}>
                {buttonLabel}
              </FsButtonSecondary>
            </S.Action>
          )}
        </S.Info>
        {contactheading && (
          <S.Contact>
            <FsHeading size={HeadingSize['2XS']} tag={HeadingTag.H3}>
              {contactheading}
            </FsHeading>
            <FsDescription>{contactdescription}</FsDescription>
          </S.Contact>
        )}
      </S.Container>
    </div>
  );
};
