import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import Image from 'next/image';

export const Container = styled.div<{ $inverse: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: var(--spacing-5x);
  display: flex;
  flex-direction: column;
  height: 442px;
  background-color: ${({ $inverse }) =>
    $inverse ? 'var(--elements-bg-color-03)' : 'var(--elements-bg-color-02)'};

  ${media.lessThan('mobile')} {
    padding: var(--spacing-5x) var(--spacing-3x);
    height: 328px;
  }
`;

export const CustomImage = styled(Image)`
  margin-bottom: auto;
`;

export const IconWrapper = styled.div`
  margin-bottom: auto;
`;

export const HeadingWrapper = styled.div`
  margin-bottom: var(--spacing-1_5x);

  ${media.lessThan('mobile')} {
    margin-bottom: var(--spacing-3x);
  }
`;
