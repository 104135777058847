'use client';

import {
  ButtonIconHighlightSize,
  FsButtonIconHighlight,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import React, { useState } from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './CarouselPress.styles';
import { CardPress, CardPressProps } from './CardPress';

interface CarouselProps {
  heading?: string;
  cards: CardPressProps[];
}
export const CarouselPress = ({
  heading = 'Heading',
  cards,
}: CarouselProps) => {
  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex >= totalCards - 4 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex <= 0 ? totalCards - 4 : prevIndex - 1,
    );
  };

  const { isMobile } = useLayout();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const totalCards = cards.length;

  return (
    <S.Carousel>
      <S.HeadingControlsWrapper>
        <FsHeading tag={HeadingTag.H3} size={HeadingSize.MD}>
          {heading}
        </FsHeading>
        <S.ButtonsWrapper>
          <FsButtonIconHighlight
            size={ButtonIconHighlightSize.LG}
            icon="NavigationLeftLine"
            onClick={handlePrev}
            disabled={activeIndex === 0}
          />
          <FsButtonIconHighlight
            size={ButtonIconHighlightSize.LG}
            icon="NavigationRightLine"
            onClick={handleNext}
            disabled={activeIndex === totalCards - 4}
          />
        </S.ButtonsWrapper>
      </S.HeadingControlsWrapper>
      <S.CardsCarouselContainer>
        {isMobile ? (
          <S.CardsCarouselWrapper>
            {(cards || []).map((card, index) => (
              <CardPress {...card} key={index} />
            ))}
          </S.CardsCarouselWrapper>
        ) : (
          <S.CardsCarouselWrapper
            style={{
              transform: `translateX(-${activeIndex * (101 / totalCards)}%)`,
              width: `${totalCards * (100.7 / 4)}%`,
            }}
          >
            {(cards || []).map((card, index) => (
              <CardPress {...card} key={index} />
            ))}
          </S.CardsCarouselWrapper>
        )}
      </S.CardsCarouselContainer>
    </S.Carousel>
  );
};
