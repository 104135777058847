import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  box-sizing: border-box;
  background-color: black;
  min-height: 480px;
  max-height: 100dvh;
`;

export const Video = styled.video`
  max-width: 100%;
  max-height: 100dvh;
`;
