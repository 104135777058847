'use client';

/* eslint no-nested-ternary: "off"*/
import { useLayout } from '@mf/common/components/Media/Layout';
import {
  ButtonSize,
  FlagType,
  FsButton,
  FsCheckbox,
  FsFlag,
  FsHeading,
  FsInputSelect,
  FsInputText,
  FsInputTextArea,
  FsParagraph,
  FsStep,
  FsStepper,
  FsToast,
  HeadingSize,
  HeadingTag,
  IconPosition,
  StepperVariant,
  ToastType,
} from '@printi/ds-offset-react-core';
import Cookies from 'js-cookie';
import { useSearchParams } from 'next/navigation';
import React, { useCallback, useEffect, useState } from 'react';
import {
  isValidCNPJ,
  isValidEmail,
  isValidPhone,
} from '@brazilian-utils/brazilian-utils';
import * as S from './ContactSection.styles';

export interface ContactSectionProps {
  mainHeading?: string | React.ReactNode;
  mainParagraph?: string | React.ReactNode;
  secondHeading?: string | React.ReactNode;
  secondParagraph?: string | React.ReactNode;
}

export const ContactSection = ({
  mainHeading,
  mainParagraph,
  secondHeading,
  secondParagraph,
}: ContactSectionProps) => {
  const { isMobile } = useLayout();
  const [formStep, setFormStep] = useState<'stepOne' | 'stepTwo'>('stepOne');
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [ipAddress, setIpAddress] = useState<string>('');
  const [isFormSucess, setIsFormSucess] = useState<boolean>(false);
  const [isToastFailOpen, setIsToastFailOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [validCNPJ, setValidCNPJ] = useState<'true' | 'false' | ''>('');
  const [validEmail, setEmailCNPJ] = useState<'true' | 'false' | ''>('');
  const [validPhone, setPhoneCNPJ] = useState<'true' | 'false' | ''>('');
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    cnpj: '',
    phone: '',
    email: '',
    howKnewAboutUs: '',
    message: '',
    acceptNews: false,
  });

  const searchParams = useSearchParams();

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  }, []);

  // eslint-disable-next-line
  const handleSelectOptions = (e: any) => {
    const selectedId = e?.target?.value;
    const selectedOption =
      selectOptions.find((item) => item.id === selectedId) || null;

    if (selectedOption) {
      setFormData((prevData) => ({
        ...prevData,
        howKnewAboutUs: selectedOption.id,
      }));
    }
  };

  const selectOptions = [
    {
      id: 'Ferramenta de busca (Google, Bing, etc.)',
      name: 'Ferramenta de busca (Google, Bing, etc.)',
    },
    {
      id: 'Rede Social (Instagram, LinkedIn, etc.)',
      name: 'Rede Social (Instagram, LinkedIn, etc.)',
    },
    { id: 'Evento', name: 'Evento' },
    {
      id: 'Anúncio',
      name: 'Anúncio',
    },
    { id: 'Indicação', name: 'Indicação' },
  ];

  const handleFormFirstStepClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFormStep('stepTwo');
  };

  const handleFormSubmit = (e: {
    name?: string;
    companyName?: string;
    cnpj?: string;
    phone?: string;
    email?: string;
    howKnewAboutUs?: string;
    message?: string;
    acceptNews?: boolean;
    // eslint-disable-next-line
    preventDefault?: any;
  }) => {
    e.preventDefault();
    const PORTAL_ID = String(process.env.HUBSPOT_PORTAL_ID);
    const CORP_FORM_ID = String(process.env.HUBSPOT_CORP_FORM_ID);
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${CORP_FORM_ID}`;

    const firstName = formData.name.split(' ', 2)[0];
    const lastName = formData.name.split(' ').slice(1).join(' ');

    const payload = {
      context: {
        hutk: Cookies.get('hubspotutk') || null,
        pageUri: 'www.printi.com.br/corporativo/',
        pageName: 'Corporativo | Printi',
        ipAddress: ipAddress,
      },
      fields: [
        { objectTypeId: '0-1', name: 'firstname', value: firstName },
        { objectTypeId: '0-1', name: 'lastname', value: lastName },
        { objectTypeId: '0-1', name: 'company', value: formData.companyName },
        {
          objectTypeId: '0-1',
          name: 'cnpj__c',
          value: formData.cnpj,
        },
        { objectTypeId: '0-1', name: 'phone', value: formData.phone },
        { objectTypeId: '0-1', name: 'email', value: formData.email },
        {
          objectTypeId: '0-1',
          name: 'como_voce_conheceu_a_printi_',
          value: formData.howKnewAboutUs,
        },
        {
          objectTypeId: '0-1',
          name: 'message',
          value: formData.message,
        },
        {
          objectTypeId: '0-1',
          name: 'utm_medium',
          value: searchParams.get('utm_medium') || '',
        },
        {
          objectTypeId: '0-1',
          name: 'utm_content',
          value: searchParams.get('utm_content') || '',
        },
        {
          objectTypeId: '0-1',
          name: 'utm_campaign',
          value: searchParams.get('utm_campaign') || '',
        },
        {
          objectTypeId: '0-1',
          name: 'utm_term',
          value: searchParams.get('utm_term') || '',
        },
        {
          objectTypeId: '0-1',
          name: 'utm_source',
          value: searchParams.get('utm_source') || '',
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'Ao avançar, você confirma estar ciente e aceitar todos os nossos Termos de uso e nossa Política de Privacidade.',
          communications: [
            {
              value: formData.acceptNews ? true : false,
              subscriptionTypeId: 247164573,
              text: 'Aceito receber ofertas exclusivas e novidades por e-mail.',
            },
          ],
        },
      },
    };
    setLoadingForm(true);

    setTimeout(async () => {
      setLoadingForm(false);
      try {
        const response = await fetch(hubspotUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          setIsFormSucess(true);
        } else {
          setIsToastFailOpen(true);
        }
      } catch (error) {
        setIsToastFailOpen(true);
      }
      setFormData({
        name: '',
        companyName: '',
        cnpj: '',
        phone: '',
        email: '',
        howKnewAboutUs: '',
        message: '',
        acceptNews: false,
      });
      setFormStep('stepOne');
    }, 1500);
  };

  useEffect(() => {
    const { name, companyName, cnpj, phone, email } = formData;
    if (name && companyName && cnpj && phone && email) {
      if (
        validCNPJ === 'true' &&
        validEmail === 'true' &&
        validPhone === 'true'
      ) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    } else {
      setIsNextDisabled(true);
    }
  }, [formData, validCNPJ, validEmail, validPhone]);

  useEffect(() => {
    const { message } = formData;
    if (message) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [formData]);

  const selectedOption = selectOptions.find(
    (item) => item.id === formData.howKnewAboutUs,
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setIsFormSucess(false);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleValidation = (
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  ) => {
    if (e.target.id === 'cnpj') {
      setValidCNPJ(isValidCNPJ(e.target.value) ? 'true' : 'false');
    }

    if (e.target.id === 'email') {
      setEmailCNPJ(isValidEmail(e.target.value) ? 'true' : 'false');
    }

    if (e.target.id === 'phone') {
      setPhoneCNPJ(isValidPhone(e.target.value) ? 'true' : 'false');
    }
  };

  const verifyPhoneMask = useCallback((phone: string) => {
    if (phone.length === 13) {
      return 'phone';
    } else {
      return 'cellPhone';
    }
  }, []);

  const renderForm = () => {
    return (
      <S.FormWrapper>
        {isFormSucess && (
          <FsFlag
            heading="Mensagem enviada!"
            type={FlagType.Positive}
            iconPosition={IconPosition.Left}
          >
            Responderemos em breve.
          </FsFlag>
        )}
        <S.HeadingStepperWrapper>
          <FsHeading
            size={isMobile ? HeadingSize.XS : HeadingSize.SM}
            tag={HeadingTag.H3}
          >
            {formStep === 'stepOne' ? 'Seus dados' : 'Sua demanda'}
          </FsHeading>
          <S.StepperWrapper>
            <FsStepper
              variant={StepperVariant.Linear}
              current={formStep === 'stepOne' ? 1 : 2}
            >
              <FsStep index={1} />
              <FsStep index={2} />
            </FsStepper>
          </S.StepperWrapper>
        </S.HeadingStepperWrapper>
        <S.Form>
          {formStep === 'stepOne' ? (
            <>
              <FsInputText
                id="name"
                label="Nome completo *"
                placeholder="Ex: Maria Silva"
                maskType={'default'}
                value={formData.name}
                onChange={handleInputChange}
              />
              <S.InputGroup>
                <FsInputText
                  id="companyName"
                  label="Nome da empresa *"
                  placeholder="Ex: Printi"
                  maskType={'default'}
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
                <FsInputText
                  id="cnpj"
                  label="CNPJ *"
                  placeholder="00.000.000/0000-00"
                  maskType={'cnpj'}
                  value={formData.cnpj}
                  supportText={
                    validCNPJ === ''
                      ? ''
                      : validCNPJ === 'true'
                        ? ''
                        : 'Insira um CNPJ válido'
                  }
                  error={
                    validCNPJ === ''
                      ? false
                      : validCNPJ === 'true'
                        ? false
                        : true
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e);
                    handleValidation(e);
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleInputChange(e);
                    handleValidation(e);
                  }}
                />
              </S.InputGroup>
              <FsInputText
                id="phone"
                label="Seu telefone *"
                placeholder="(00) 00000-0000"
                maskType={verifyPhoneMask(formData.phone)}
                value={formData.phone}
                supportText={
                  validPhone === ''
                    ? ''
                    : validPhone === 'true'
                      ? ''
                      : 'Insira um número válido'
                }
                error={
                  validPhone === ''
                    ? false
                    : validPhone === 'true'
                      ? false
                      : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  handleValidation(e);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  handleValidation(e);
                }}
              />
              <FsInputText
                id="email"
                label="Email corporativo *"
                maskType={'default'}
                placeholder="Ex.: maria.silva@printi.com.br"
                value={formData.email}
                supportText={
                  validEmail === ''
                    ? ''
                    : validEmail === 'true'
                      ? ''
                      : 'Insira um e-mail válido'
                }
                error={
                  validEmail === ''
                    ? false
                    : validEmail === 'true'
                      ? false
                      : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  handleValidation(e);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  handleValidation(e);
                }}
              />
              <S.ButtonWrapper>
                <FsButton
                  onClick={handleFormFirstStepClick}
                  size={ButtonSize.LG}
                  disabled={isNextDisabled}
                >
                  Avançar
                </FsButton>
              </S.ButtonWrapper>
            </>
          ) : (
            <>
              <FsInputSelect
                id="howKnewAboutUs"
                label="Como você conheceu a Printi?"
                placeholder="Selecione uma opção"
                value={selectedOption?.name}
                onChange={handleSelectOptions}
              >
                {selectOptions.map((option, index) => (
                  <React.Fragment key={index}>{option.name}</React.Fragment>
                ))}
              </FsInputSelect>
              <FsInputTextArea
                id="message"
                label="Especifique aqui sua demanda *"
                placeholder="Descreva em poucas palavras como podemos te ajudar. "
                value={formData.message}
                onChange={handleTextAreaChange}
              />
              <S.CheckboxWrapper>
                <FsCheckbox
                  tabIndex={0}
                  id="acceptNews"
                  checked={false}
                  onChange={handleCheckboxChange}
                >
                  Aceito receber ofertas exclusivas e novidades por e-mail.
                </FsCheckbox>
              </S.CheckboxWrapper>
              <S.TermsPrivacyWarning>
                Ao avançar, você confirma estar ciente e aceitar todos os nossos{' '}
                <a href="/termos-de-uso/">Termos de uso</a> e nossa{' '}
                <a href="/politicas-de-privacidade/">Política de Privacidade</a>
                .
              </S.TermsPrivacyWarning>
              <S.ButtonWrapper>
                <FsButton
                  disabled={isSubmitDisabled}
                  onClick={handleFormSubmit}
                  size={ButtonSize.LG}
                  loading={loadingForm}
                >
                  Enviar
                </FsButton>
              </S.ButtonWrapper>
            </>
          )}
        </S.Form>
      </S.FormWrapper>
    );
  };

  return (
    <S.Container>
      <S.Info>
        <S.MainInfoWrapper>
          <FsHeading
            size={isMobile ? HeadingSize.LG : HeadingSize.XL}
            tag={HeadingTag.H2}
          >
            {mainHeading}
          </FsHeading>
          <FsParagraph>{mainParagraph}</FsParagraph>
        </S.MainInfoWrapper>
        <S.SecondInfoWrapper>
          <FsHeading size={HeadingSize.XS} tag={HeadingTag.H3}>
            {secondHeading}
          </FsHeading>
          <FsParagraph>{secondParagraph}</FsParagraph>
        </S.SecondInfoWrapper>
        {isMobile ? renderForm() : null}
      </S.Info>
      {isMobile ? null : renderForm()}
      <FsToast
        type={ToastType.Negative}
        opened={isToastFailOpen}
        showButton={false}
      >
        Aconteceu algum problema ao tentar enviar a mensagem, tente novamente.
      </FsToast>
    </S.Container>
  );
};
